<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Productos Marketplace</h4>
            <div class="small text-muted">Administración de productos de marketplace</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Productos">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>
      
      <b-row>
        <b-col lg="12" id="view_filters_products">    
          <b-card v-if="table.mostrarFiltros" id="list-products">
            <b-row>
              <b-col sm="3">
                <v-select :options="arr.select.type" v-model="filters.type" placeholder="Unidad de negocio" :multiple="false" :select-on-tab="true" @input="filterLoadCategorys"></v-select>
                <v-select :options="arr.select.categorys" v-model="filters.categorys" placeholder="Categorías" :multiple="false" :select-on-tab="true" @input="filterLoadSubCategorys" ></v-select>
                <v-select :options="arr.select.subcategorys" v-model="filters.subcategorys" placeholder="Subcategorías" :multiple="true" :select-on-tab="true"></v-select>             
              </b-col>  
                    
              <b-col sm="3">                
                <v-select :options="arr.select.customers" v-model="filters.customers" placeholder="Clientes" :multiple="false" :select-on-tab="true" @search="filterCustomers"></v-select>                
                <v-select :options="arr.select.customers" v-model="filters.interested" placeholder="Interesados" :multiple="false" :select-on-tab="true" @search="filterCustomers"></v-select>
                <v-select :options="arr.select.statusMarketplace" v-model="filters.statusMarketplace" placeholder="Proceso" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>

              <b-col sm="2">
                <v-select :options="arr.select.active" v-model="filters.active" placeholder="Estado" :multiple="false" :select-on-tab="true"></v-select>                
                <v-select :options="arr.select.published" v-model="filters.published" placeholder="Publicado" :multiple="false" :select-on-tab="true"></v-select>
                <v-select :options="arr.select.featured" v-model="filters.featured" placeholder="Destacados" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>  

              <b-col sm="2">                
                <v-select :options="arr.select.sold" v-model="filters.sold" placeholder="Vendido" :multiple="false" :select-on-tab="true"></v-select>                
                <v-select :options="arr.select.publishedInstagram" v-model="filters.publishedInstagram" placeholder="Instagram" :multiple="false" :select-on-tab="true"></v-select>
                <b-form-input type="text" 
                              placeholder="Ingrese un código o nombre de producto" 
                              v-model="filters.name"
                              size="sm"
                              v-on:keyup.enter.native="filterProducts">
                </b-form-input>                 
              </b-col>

              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterProducts()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_list_products">
          <b-card>
            <b-row>
              <b-col sm="12" class="mt-2">
                <b-table class="mb-0 table-products-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        :busy="table.isBusy"                     
                        v-if="table.items.length || table.isBusy">                                               
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>
                                                  
                    <template v-slot:cell(id)="data">
                      <b>{{data.item.id}}</b>
                    </template>

                    <template v-slot:cell(name)="data">
                      <div class="crud-products-title">
                        <span v-if="data.item.code" class="crud-products-code">
                          {{data.item.code}} 

                          <!-- el campo sold, es un campo que se carga cuando es usa el modo MARKETPLACE -->
                          <!-- si el campo esta en false, muestra si esta publicado o no -->
                          <span>                             
                            <b-badge variant="secondary" v-if="data.item.status_marketplace=='pending_approval'">PENDIENTE DE PUBLICACIÓN</b-badge>

                            <b-badge variant="success" v-if="data.item.status_marketplace=='published'">PUBLICADO</b-badge>                            
                            <b-badge variant="warning" v-if="data.item.status_marketplace=='limit_interested'">SUSPENDIDO</b-badge>
                            
                            <b-badge variant="danger" v-if="data.item.status_marketplace=='out_stock' && data.item.status_marketplace_observations">FUERA DE STOCK ({{data.item.status_marketplace_observations}})</b-badge>
                            <b-badge variant="danger" v-if="data.item.status_marketplace=='out_stock' && !data.item.status_marketplace_observations">FUERA DE STOCK</b-badge>

                            <b-badge variant="info" v-if="data.item.status_marketplace=='sold'">VENDIDO</b-badge>
                            <b-badge variant="danger" v-if="data.item.status_marketplace=='rejected'">RECHAZADO</b-badge>
                          </span>

                          <span v-if="data.item.featured" title="Producto Destacado">
                              <i class="fa fa-fire crud-product-featured-custom"></i>
                          </span>
                        </span><br>
                        {{data.item.name}}
                      </div>
                    </template>

                    <template v-slot:cell(image)="data">
                      <b-img  :src="data.item.image[0]" 
                              fluid 
                              class="crud-product-image" 
                              v-if="data.item.image"/>
                      <b-icon icon="image" 
                              v-else 
                              class="h2" 
                              v-b-tooltip.hover
                              title="Imagen No Disponible" />
                    </template>                
                    
                    <template v-slot:cell(prices)="data">                                
                      <div v-if="data.item.prices.length<3">
                        <div v-for="element in data.item.prices" :key="element.id">
                          <b :title="element.list.name" v-b-tooltip.hover>
                            <span v-if="element.list.reference">
                              {{element.list.reference.substring(0,5)}}
                            </span>
                            <span v-else>
                              {{element.list.name.substring(0,5)}}
                            </span>                              
                          </b> : 
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency:element.list.currency.code}).format(element.amount_total)}} 
                          <span v-if="element.unit_measurement_result">{{element.unit_measurement_result.reference}}</span>
                        </div>
                      </div>
                      <div v-else>
                        <a href="javascript:void(0)" @click="setPricesProducts(data.item)">Ver Listas</a>
                      </div>
                    </template>
                    
                    <template v-slot:cell(customer)="data">
                      <div v-if="data.item.customer">                        
                        <b-avatar :src="data.item.customer.image"
                                  v-if="data.item.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>     
                        &nbsp;<b>{{data.item.customer.name}}</b>   
                        <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="INACTIVO" />               
                      </div>
                    </template>

                    <template v-slot:cell(interested)="data">
                      <div v-if="data.item.marketplace">                        
                        <div v-if="data.item.marketplace.length">
                          <b-badge variant="success" 
                                  v-if="getInerestedActive(data.item.marketplace) < 3"
                                    class="crud-product-interested">
                            {{getInerestedActive(data.item.marketplace)}} de {{data.item.marketplace.length}} Interesado
                          </b-badge>
                          <b-badge variant="warning" 
                                  v-if="getInerestedActive(data.item.marketplace) >= 3"
                                    class="crud-product-interested">
                            {{getInerestedActive(data.item.marketplace)}} de {{data.item.marketplace.length}} Interesado
                          </b-badge>                          
                        </div>     
                      </div>
                    </template>

                    <template v-slot:cell(sold)="data">                      
                      <div v-if="data.item.sold">                        
                        <b-icon icon="check2-circle" variant="success" scale="2" title="Vendido"></b-icon>
                      </div>
                    </template>

                    <template v-slot:cell(published_instagram)="data">                      
                      <div v-if="data.item.published_instagram">                        
                        <b-icon icon="check2-circle" variant="success" scale="2" title="Instagram"></b-icon>
                      </div>
                    </template>      

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableProductsShow"
                                  @hide="setConfigTableProductsHide">
                        
                        <b-dropdown-item @click="setPricesProducts(data.item)" v-if="modules.showPrices">
                          <i class="fa fa-dollar crud-product-action-icon"></i> Precios
                        </b-dropdown-item>
                        
                        <b-dropdown-header>Marketplace</b-dropdown-header>                         
                        <b-dropdown-item v-if="parameters.haveMarketplace" @click="openInterested(data.item)">
                          <i class="fa fa-comment crud-product-action-icon ml-0 mr-0"></i> Interesados
                        </b-dropdown-item>
                        <b-dropdown-item v-if="parameters.haveMarketplace" @click="productRefused(data.item)" v-show="data.item.active && !data.item.sold">
                          <i class="fa fa-window-close crud-product-action-icon ml-0 mr-0"></i> Rechazar
                        </b-dropdown-item>
                        <b-dropdown-item v-if="parameters.haveMarketplace" @click="checkPublishedInstagram(data.item)" v-show="data.item.active && !data.item.published_instagram">
                          <i class="fa fa-instagram crud-product-action-icon ml-0 mr-0"></i> Post
                        </b-dropdown-item>
                        <b-dropdown-item @click="uncheckPublishedInstagram(data.item)" v-if="data.item.published_instagram">
                          <i class="fa fa-instagram crud-product-action-icon ml-0 mr-0"></i> No Post
                        </b-dropdown-item>

                        <b-dropdown-header>Acciones</b-dropdown-header>                         
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                        </b-dropdown-item>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>          

      <b-row>
        <b-col :lg="getColsProduct" class="pr-0" id="view_footer_products">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"  
                                @input="filterProducts()"/>
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-tabs card>
          <b-tab no-body title="General">
            <b-row>
              <b-col md="6" class="mb-4">
                <b-form-checkbox v-model="crud.form.active" 
                                  switch 
                                  size="sm" 
                                  class="pull-left">
                  Activar (disponible en el sistema)
                </b-form-checkbox> 
              </b-col>
              <b-col md="6" class="mb-4">
                <b-form-checkbox v-model="crud.form.refused" 
                                  switch 
                                  size="sm" 
                                  class="pull-right"
                                  v-if="!crud.form.active">
                  Rechazado
                </b-form-checkbox>                            
              </b-col>                   
            </b-row>
            <b-row>                        
              <b-col md="3">
                <b-form-group label="Código">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.code"
                                required
                                placeholder="Ingresar un código">
                  </b-form-input>
                </b-form-group>
              </b-col>                   
              <b-col md="9">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>  
              <b-col md="12">
                <b-form-group label="Descripción Corta">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.description_short"                                
                                placeholder="Ingresar una descripción">
                  </b-form-input>
                </b-form-group>
              </b-col>                 
            </b-row>     

            <b-row>   
              <b-col>
                <FindObject type="productsSubCategory" 
                            @select-object="loadProductsSubcategory($event)" 
                            :valueID="crud.form.subcategory_id"
                            render="search"/>
              </b-col>
            </b-row>   
            <b-row>
              <b-col>
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadProductsCustomer($event)" 
                            :valueID="crud.form.customer_id"/>        
              </b-col>
            </b-row>       
          </b-tab>
        
          <b-tab no-body title="Detalle">
            <b-row>                               
              <b-col md="4">                
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Galería">
                      <Imagen :images="crud.form.image" 
                              @load-image="crud.form.image = $event" 
                              typeImage="Imagenes" 
                              :multipleImage="true" 
                              :compressImage="true"/>
                    </b-form-group>                            
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Adjunto">                
                      <b-form-file v-if="!crud.form.fileLink" 
                                    v-model="crud.form.file"                             
                                    size="sm"
                                    :state="Boolean(crud.form.file)"
                                    placeholder="Elija un archivo o sueltelo aquí..."
                                    drop-placeholder="Suelta el archivo aquí...">
                      </b-form-file>      
                      <div v-else>
                        <b-link :href="crud.form.fileLink" target="_blank">
                          <b>Ver Archivo</b>
                        </b-link>           
                        <b-button type="button" variant="outline-dark" size="sm" class="btn-pill ml-2" @click="crud.form.fileLink=''" v-b-tooltip.hover title="Cargar otro archivo">
                          <i class="fa fa-refresh"></i>
                        </b-button>                                         
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="8">
                <b-row>
                  <b-col md="4">                    
                    <b-form-checkbox v-model="crud.form.published" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Publicado <b-icon icon="info-circle" style="cursor: pointer;" title="Muestra en sitio web"> </b-icon>
                    </b-form-checkbox>            
                  </b-col>       
                  <b-col md="4">
                    <b-form-checkbox v-model="crud.form.published_instagram" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Publicado IG <b-icon icon="info-circle" style="cursor: pointer;" title="Marcar si fue realizado el post en Instagram"> </b-icon>
                    </b-form-checkbox>            
                  </b-col>                                 
                  <b-col md="4">                    
                    <b-form-checkbox v-model="crud.form.featured" 
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                      Destacado <b-icon icon="info-circle" style="cursor: pointer;" title="Muestra en bloque destacados"> </b-icon>
                    </b-form-checkbox>            
                  </b-col>         

                  <b-col md="12">
                    <hr>
                  </b-col>           
                  <b-col md="12">
                    <b-form-group label="Descripción Larga">
                      <vue-editor v-model="crud.form.description"></vue-editor>                              
                    </b-form-group>      
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Campos Extra" v-if="productFields.extra.length">
            <b-row>
              <b-col sm="12" 
                    v-for="(element, index) in productFields.extra" 
                    v-bind:key="index">                    

                <b-form-group :label="element.field.toUpperCase()">                  
                  <b-form-input type="text"
                                size="sm"
                                v-model="productFields.extra[index].value">
                  </b-form-input>
                </b-form-group>
              
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title="Precios" v-if="showPriceEdit">
            <b-row>
              <b-col lg="5">
                <b-table class="mb-0 crud-products-table-prices"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="tablePrices.items"
                        :fields="tablePrices.fields"                            
                        v-if="tablePrices.items.length">   

                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template> 
                  
                  <template v-slot:cell(list)="data">                
                    <div v-if="data.item.list">
                      {{data.item.list.name}}
                      <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                        (Pública)
                      </span>
                    </div>
                  </template>                                                
                  
                  <template v-slot:cell(price_list)="data">                
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_initial)}}                
                    <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
                  </template>

                  <template v-slot:cell(price_public)="data">                
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                    <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
                  </template>

                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron precios para este producto</b-alert>
              </b-col> 
            </b-row>
          </b-tab>              
        </b-tabs>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD PRECIOS -->
      <b-modal  v-model="modal.formPrice.active"
                header-bg-variant="dark"
                header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formPrice.title}}
        </div>

        <b-row>         
          <b-col md="12">
            <div class="crud-products-title" v-if="itemSelected">
              <div class="crud-products-title">
                <span v-if="itemSelected.code" class="crud-products-code">
                  {{this.itemSelected.code}} 
                </span><br>
                {{this.itemSelected.name}}
              </div>
              <hr>
            </div>
          </b-col>
          <b-col md="5">
            <b-form-group label="Lista">
              <v-select :options="arr.select.listPrice" 
                        v-model="crud.formPrice.price_list_select" 
                        placeholder="Lista"
                        :disabled="crud.formPrice.id>0">
              </v-select>
            </b-form-group>
          </b-col>    
          <b-col md="4">
            <b-form-group label="Importe">
              <b-form-input type="number"
                            v-model="crud.formPrice.amount"
                            step="0.01"
                            placeholder="Ingresar el precio">
              </b-form-input>
            </b-form-group>            
          </b-col>
          <b-col md="3">                
            <b-button variant="dark" 
                      @click="saveFormPrice()"
                      title="Guardar Precio"
                      class="crud-products-button-save-price pull-right">
              <b-icon icon="plus" v-if="crud.formPrice.id==0"></b-icon>
              <i class="fa fa-save" v-else></i>
            </b-button> 
            <b-button variant="outline-danger" 
                      @click="cancelSaveFormPrice()"
                      title="Cancelar Edición"
                      class="crud-products-button-save-price pull-right mr-1"                      
                      v-if="crud.formPrice.id>0">
              <i class="fa fa-close"></i>
            </b-button>                                  
          </b-col>
          <b-col md="12">
            <b-table class="mb-0 crud-products-table-prices"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="tablePrices.items"
                    :fields="tablePrices.fields"                            
                    v-if="tablePrices.items.length">   

              <template v-slot:table-colgroup="scope">
                <col
                  v-for="field in scope.fields"                    
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template> 
              
              <template v-slot:cell(list)="data">                
                <div v-if="data.item.list">
                  {{data.item.list.name}}
                  <span v-if="data.item.list.public" class="crud-product-list-prices-public">
                    (Pública)
                  </span>
                </div>
              </template>                                                
              
              <template v-slot:cell(price_list)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_initial)}}                
                <span v-if="data.item.unit_measurement_initial">{{data.item.unit_measurement_initial.reference}}</span>
              </template>

              <template v-slot:cell(price_public)="data">                
                {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.list.currency.code}).format(data.item.amount_total)}}                
                <span v-if="data.item.unit_measurement_result">{{data.item.unit_measurement_result.reference}}</span>
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right 
                            size="sm" 
                            variant="outline-dark" 
                            class="pull-right" 
                            :disabled="crud.formPrice.id>0">
                  <b-dropdown-item @click="editPricesProducts(data.item)">
                    <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="removePricesProducts(data.item)">
                    <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                  </b-dropdown-item>
                </b-dropdown>
              </template>

            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron precios para este producto</b-alert>
          </b-col> 
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formPrice.active=false">Salir</b-button>          
        </div>
      </b-modal>
          
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'  
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Imagen from '@/components/inc/image/image' 
  import Param from '@/config/parameters'
  import { VueEditor } from "vue2-editor"
  import Storage from '@/handler/storageSession'

  export default {
    components: {
      Imagen,
      FindObject,
      VueEditor,
    },    
    data: () => {
      return {      
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudProductsMarketplace',
          elements: {}
        }, 
        parameters: {          
          haveMeasurement: Helper.hasParametersAccess(Param.P10),          
          haveMarketplace: Helper.hasParametersAccess(Param.P18),          
          hasMultiLevelCategory: Helper.hasParametersAccess(Param.P20),
        },         
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,
        },
        tablePrices : {
          items: [],
          fields: [
            {key: 'list', label: 'Lista', class:"align-middle", width:"30%"},
            {key: 'price_list', label: 'Precio Lista', class:"align-middle text-right", width:"30%"},
            {key: 'price_public', label: 'Precio Público', class:"align-middle text-right", width:"30%"},
            {key: 'f_action', label:'', class:"align-middle", width:"10%"},
          ]
        },
        crud: {
          form: {
            id: 0,
            name: '',            
            subline: null,
            subline_id: 0,
            subcategory: null,
            subcategory_id: 0,
            customer: null,
            customer_id: 0,
            code: '',
            image: [],            
            depth: 0,
            long: 0,
            height: 0,
            weight: 0,
            active: true,
            featured: false,
            description: '',            
            description_short: '',     
            fileLink: '',
            file: null,
            published: true,
            fields_optionals: '',
            product_related_type_view: 'default',
            product_related_subcategory_id: 0,
            refused: false,
            variants: [],
            newVariants: '',
            erp_iva_condition: null,
            erp_iva_condition_id: 0,
            accounting_accounts: null,
            accounting_accounts_id: 0,
            videos: [],
            unit_measurement_purchase: null,
            unit_measurement_purchase_id: 0,
            unit_measurement_sale_id: 0,
            unit_measurement_sale: null,
            unit_measurement_sale_conversion: 0,
            haveCostProductPurchase: false,
            published_instagram: false,
          },
          formPrice: {
            id: 0,
            products_id: 0,
            price_list_id: 0,
            price_list_select: null,
            amount: 0,
            amount_old: 0
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },       
          formPrice: {
            active: false,
            title: ''
          },
        },
        arr: {
          products: [],          
          select:{
            listPrice: [],            
            categorys: [],
            subcategorys:[],
            customers: [],
            type: [
              {code:'titolovende', label: 'TITO LO VENDE'},
              {code:'titoloconstruye', label: 'TITO LO CONSTRUYE'},                       
            ],            
            active:[
              {code:true, label: 'Activo'},
              {code:false, label: 'Inactivo'},              
            ],
            featured:[
              {code:true, label: 'Destacados'},
              {code:false, label: 'Sin Destacar'},              
            ],
            published:[
              {code:true, label: 'Publicado'},
              {code:false, label: 'Sin Publicar'},              
            ],
            sold:[
              {code:true, label: 'Vendido'},
              {code:false, label: 'Sin Vender'},              
            ],
            statusMarketplace: [
              {code: 'pending_approval', label: 'Pendiente de Aprobación'},
              {code: 'published', label: 'Publicados'},
              {code: 'limit_interested', label: '3 Interesados'},
              {code: 'out_stock_inactive_user', label: 'Fuera de Stock (Inactivo Usuarios)'},
              {code: 'out_stock_inactive_staff', label: 'Fuera de Stock (Inactivo Staff)'},
              {code: 'out_stock_email_omitted', label: 'Fuera de Stock (Email No Contestado)'},
              {code: 'out_stock_down_email', label: 'Fuera de Stock (Baja Desde Email)'},
              {code: 'out_stock_not_qualified', label: 'Fuera de Stock (Sin Clasificar)'},
              {code: 'sold', label: 'Vendidos'},
              {code: 'rejected', label: 'Rechazados'},
            ],
            publishedInstagram:[
              {code:true, label: 'Post (Instagram)'},
              {code:false, label: 'No Post (Instagram)'},              
            ],   
          }          
        },   
        filters: {
          type: {code:'titolovende', label: 'TITO LO VENDE'},
          categorys: null,
          subcategorys: null,
          customers: null,
          interested: null,
          name: '',
          active: {code:true, label: 'Activo'},
          featured: null,
          published: null,
          sold: null, 
          statusMarketplace: null,
          publishedInstagram: null,
        },
        itemSelected: null,   
        itemSelectedVariant: null,
        showVariant: false,
        showPriceEdit: false,
        showPriceVariantEdit: false,
        modulesActive: [], 
        modules: {
          showPrices: false
        },
        productFields: {
          extra: []
        },
        optProductRelatedTypeView: [
          { text: 'Mostrar productos de su misma categoría', value: 'default', },
          { text: 'Mostrar productos de una subcategoría especifica', value: 'show_product_related_subcategory', },
        ],  
        optProductRelatedSubcategory: [],        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {      
      this.modulesActive = Session.getSession().auth.user.permissions.modules
      this.configAccess()

      this.loadFieldTable()      
      this.filterLoad()
      this.loadListPrice() 
      this.loadStyleConfig()        

      this.filterProducts()
    },
    computed: {
      hasAnyVariant() {      
        return this.hasColors || this.hasWaist || this.hasColorsWaist
      },
      hasAllVariant() {      
        return this.hasColors && this.hasWaist && this.hasColorsWaist
      },      
      hasMeasurement() {        
        return this.parameters.haveMeasurement
      },
      getColsProduct() {
        if(this.hasAnyVariant) {
          return 7 
        } else {
          return 12
        }
      },
      stateProductVariant() {        
        return this.crud.form.variants.indexOf(this.crud.form.newVariants.trim()) > -1 ? false : null
      },
      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
          return [
              {field: 'active', condition: true},
              {field: 'type', condition: 'Ingresos'}
          ];
      },
    },
    methods: {
      loadStyleConfig () {
        var view_filter_products = document.getElementById('view_filters_products')
        var view_list_products = document.getElementById('view_list_products')
        var view_footer_products = document.getElementById('view_footer_products')

        if(this.hasAnyVariant) {
          var padre = document.getElementById('contain_variant_items')
          var hijo = document.getElementById('variant_items')  
          
          hijo.style.width = padre.clientWidth - 45 + 'px'

          if(window.innerWidth < 991) {
            hijo.style.width = '100%'
            hijo.style.position = "relative"
            hijo.style.right = 'auto'

            //view_filter_products.classList.remove('pr-0')
            view_list_products.classList.remove('pr-0')
            view_footer_products.classList.remove('pr-0')
          } else {
            //view_filter_products.classList.add('pr-0')
            view_list_products.classList.add('pr-0')
            view_footer_products.classList.add('pr-0')
          }
        } else {
          //view_filter_products.classList.remove('pr-0')
          view_list_products.classList.remove('pr-0')
          view_footer_products.classList.remove('pr-0')          
        }
      },
      loadFieldTable () {
        // TABLE PRODUCTOS
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class:"align-middle text-center", width:"30px"})
        this.table.fields.push({key: 'image', label: 'Imagen', class:"align-middle text-center", width:"60px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"250px"})              
        if(this.modules.showPrices) {
          this.table.fields.push({key: 'prices', label: 'Precio Público', class:"align-middle", width:"150px"})      
        }         
        this.table.fields.push({key: 'customer', label: 'Clientes', class:"align-middle text-left", width:"150px"})      
        this.table.fields.push({key: 'interested', label: 'Interesados Activos', class:"align-middle text-left", width:"100px"})      
        this.table.fields.push({key: 'sold', label: 'Vendido', class:"align-middle text-center", width:"100px"})      
        this.table.fields.push({key: 'published_instagram', label: 'Instagram', class:"align-middle text-center", width:"100px"})
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})
      },
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.products.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openVariant(element)            
              //this.openDetail(index)                         
            }
          }
        })
      },     
      openDetail(index) {
        this.table.rowSelected = index          
        this.filterProducts()        
      },       
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerProducto()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.products = data

          this.table.items.forEach(element => {
            if(!element.active) {
              element._rowVariant = 'danger'  
            }
          });
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }  
          loader.hide()         
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });        
      },
      loadListPrice() {
        var result = serviceAPI.obtenerListaPreciosSimple()

        result.then((response) => {
          var data = response.data    

          data.forEach(element => {
            this.arr.select.listPrice.push({
              code: element.id,
              label: element.name
            })  
          });    
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error))
        });        
      },
      setConfigTableProductsShow() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '285px'
        }        
      },
      setConfigTableProductsHide() {
        var arrTable = document.getElementsByClassName('table-products-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },      
      configAccess() {
        this.modulesActive.forEach(element => {          
          if(element.id == Modules.PRECIOS) {                          
            this.modules.showPrices = true                             
          }
        })
      },
  
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''         
        this.crud.form.subline_id = 0      
        this.crud.form.subcategory_id = 0
        this.crud.form.customer_id = 0
        this.crud.form.code = ''
        this.crud.form.image = []        
        this.crud.form.depth = 0
        this.crud.form.long = 0
        this.crud.form.height = 0
        this.crud.form.weight = 0
        this.crud.form.active = true
        this.crud.form.featured = false
        this.crud.form.description = ''        
        this.crud.form.description_short = ''        
        this.crud.form.file = null
        this.crud.form.fileLink = ''
        this.crud.form.published = true
        this.crud.form.product_related_type_view = 'default'
        this.crud.form.product_related_subcategory_id = 0
        this.crud.form.refused = false
        this.crud.form.published_instagram = false

        this.crud.form.variants = []
        this.crud.form.newVariants = ''

        this.productFields.extra = []

        this.crud.form.erp_iva_condition = null
        this.crud.form.erp_iva_condition_id = 0

        this.crud.form.accounting_accounts = null
        this.crud.form.accounting_accounts_id = 0

        this.videosTMP = []
        
        this.crud.form.unit_measurement_purchase = null
        this.crud.form.unit_measurement_purchase_id = 0
        this.crud.form.unit_measurement_sale = null
        this.crud.form.unit_measurement_sale_id = 0
        this.crud.form.unit_measurement_sale_conversion = 0
        this.crud.form.haveCostProductPurchase = false

        this.showPriceEdit = false

        this.modal.form.title = "Nuevo Producto"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name              
        this.crud.form.subline_id =  item.subline_id        
        this.crud.form.subcategory_id = item.subcategory_id
        this.crud.form.customer_id = item.customer_id
        this.crud.form.code = item.code
        this.crud.form.image = item.image        
        this.crud.form.depth = item.depth
        this.crud.form.long = item.long
        this.crud.form.height = item.height
        this.crud.form.weight = item.weight
        this.crud.form.active = item.active
        this.crud.form.featured = item.featured
        this.crud.form.description = item.description      
        this.crud.form.description_short = (item.description_short!=null) ? item.description_short : ''
        this.crud.form.file = null
        this.crud.form.fileLink = item.attach              
        this.crud.form.published = item.published
        this.crud.form.product_related_type_view = (item.product_related_type_view!=null) ? item.product_related_type_view : 'default'
        this.crud.form.product_related_subcategory_id = (item.product_related_subcategory_id!=null) ? item.product_related_subcategory_id : 0
        this.crud.form.refused = item.refused
        this.crud.form.published_instagram = item.published_instagram
        
        if(item.variants) {
          this.crud.form.variants = item.variants.split(',')
          this.crud.form.newVariants = ''
        } else {
          this.crud.form.variants = []
          this.crud.form.newVariants = ''          
        }
                
        if(item.image) {          
          this.crud.form.image = []
          item.image.forEach(element => {
            this.crud.form.image.push({ path: element })
          });                    
        } else {
          this.crud.form.image = []
        }

        this.videosTMP = []
        if(item.videos)
          this.videosTMP = JSON.parse(item.videos)
                                
        if(item.product_purchase) {
          this.crud.form.unit_measurement_purchase = item.product_purchase.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.product_purchase.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = true
        } else {
          this.crud.form.unit_measurement_purchase = item.unit_measurement_purchase
          this.crud.form.unit_measurement_purchase_id = item.unit_measurement_purchase_id
          this.crud.form.haveCostProductPurchase = false
        }

        this.crud.form.unit_measurement_sale = item.unit_measurement_sale
        this.crud.form.unit_measurement_sale_id = item.unit_measurement_sale_id 
        this.crud.form.unit_measurement_sale_conversion = item.unit_measurement_sale_conversion

        setTimeout(()=>{
          if(item.fields_optionals) {
            this.productFields.extra = []
            var fields = JSON.parse(item.fields_optionals)
            fields.forEach(element => {
              this.productFields.extra.push({
                field: element.field,
                value: element.value,
              }) 
            });          
          } else {
            this.productFields.extra = []
          }
        },1500)

        this.crud.form.erp_iva_condition = item.erp_iva_condition
        this.crud.form.erp_iva_condition_id = item.erp_iva_condition_id

        if(item.accounting_account){
          this.crud.form.accounting_accounts = item.accounting_account.accounting_account
          this.crud.form.accounting_accounts_id = item.accounting_account.accounting_accounts_id  
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }

        this.showPriceEdit = true
        this.tablePrices.items = item.prices

        this.modal.form.title = "Editar Producto"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Producto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarProducto(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterProducts()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        var file = new FormData();
        if(this.crud.form.fileLink) {
          file.append("file", this.crud.form.fileLink);
        } else {        
          if(this.crud.form.file) {          
            file.append("file", this.crud.form.file);
          }
        }  

        this.crud.form.fields_optionals = JSON.stringify(this.productFields.extra)

        this.crud.form.videos = JSON.stringify(this.videosTMP)

        if (this.crud.form.id) {
          var result = serviceAPI.editarProducto(JSON.stringify(this.crud.form), image, file);
        } else {
          var result = serviceAPI.agregarProducto(JSON.stringify(this.crud.form), image, file);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterProducts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },  
      
      loadProductsSubcategory (object) {
        if(object){
          this.crud.form.subcategory = object
          this.crud.form.subcategory_id = object.id

          this.productFields.extra = []          
          if(this.crud.form.subcategory.fields_required) {
            this.crud.form.subcategory.fields_required.forEach(element => {
              this.productFields.extra.push({
                field: element,
                value: ''
              })
            });           
          }

          this.loadSubcategoryTypeView(object)
          
        } else {
          this.crud.form.subcategory = null
          this.crud.form.subcategory_id = 0

          this.loadSubcategoryTypeView(null)
        }
      },   
      loadVarietyProducts (object) {
        if(object){
          this.crud.formSub.products = object
          this.crud.formSub.products_id = object.id
        } else {
          this.crud.formSub.products = null
          this.crud.formSub.products_id = 0
        }
      },
      loadProductsCustomer (object) {
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id                   
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadSubcategoryTypeView (object) {
        if(object) {
          var result = serviceAPI.mostrarCategoria(object.category_id)

          result.then((response) => {
            var data = response.data              

            if(data.subcategorys) {            
              this.optProductRelatedSubcategory = []
              data.subcategorys.forEach(element => {      
                this.optProductRelatedSubcategory.push({text: element.name, value: element.id})                                                            
              });                                
            }          
          })         
        } else {
          this.optProductRelatedSubcategory = []
        }
      },      
      filterLoad() {        
        this.filterSold()         

        this.restoreFilterStorage() 
        this.filterLoadCategorys()
      },
      filterLoadCategorys() {
        if(!this.filters.type) {
          this.filters.categorys = null          
          this.arr.select.categorys = []
          
          this.filters.subcategorys = null
          this.arr.select.subcategorys = []
        } else {
          var result = serviceAPI.filtrarCategoria({
            type: this.filters.type
          })        
          result.then((response) => {
            var data = response.data    

            this.arr.select.categorys = []
            data.forEach(element => {              
              this.arr.select.categorys.push({code: element.id, label: element.name})
            });                                
          })   
        }
      },   
      filterLoadSubCategorys(val) {
        this.arr.select.subcategorys = []
        if(val) {          
          var result = serviceAPI.obtenerCategoria()        
          result.then((response) => {
            var data = response.data    

            data.forEach(element => {          
              if(element.id == val.code) {
                element.subcategorys.forEach(element1 => {  
                  
                  // si es marketpace
                  if(this.parameters.hasMultiLevelCategory) {
                    if(!element1.children.length) {
                      this.arr.select.subcategorys.push({code: element1.id, label: element1.name}) 
                    }
                  } else {                    
                    this.arr.select.subcategorys.push({code: element1.id, label: element1.name}) 
                  }                  

                }); 
              }                                  
            });                                
          })   
        }
      },
      filterCustomers(search, loading) {        
        loading(true)
        this.arr.select.customers = []

        if(search.length > 3) {          
          var result = serviceAPI.filtrarClientes({
            name: search
          })        
          result.then((response) => {
            var data = response.data.data    

            data.forEach(element => {              
              if(element.code) {
                this.arr.select.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
              } else {
                this.arr.select.customers.push({ code: element.id, label: element.name })            
              }                          
            });                                
          })        
        }
        loading(false)
      },
      filterSold() {
        if(this.parameters.haveMarketplace) {
          this.filters.sold = {code:false, label: 'Sin Vender'}
        }        
      },

      filterProducts(forceOpenPricesProducts=false) {
        this.saveFilterStorage()

        this.table.isBusy = true
        var result = serviceAPI.filtrarProducto(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data
          this.table.items = data.data          
          this.arr.products = data.data  

          this.table.items.forEach(element => { 
            if( element.status_marketplace == 'out_stock' ||
                element.status_marketplace == 'rejected') {
                
                element._rowVariant = 'danger'                          
            }

            /*
            if(!element.active) {
              element._rowVariant = 'danger'                          
            }
            */

            if(forceOpenPricesProducts) {
              if(element.id == this.itemSelected.id) {
                this.setPricesProducts(element)
              }              
            }
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }   
                    
          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
          
          this.table.isBusy = false
        })           
        .catch(error => {  
          this.table.isBusy = false
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },

      setPricesProducts(item) {
        this.crud.formPrice.id = 0
        this.crud.formPrice.products_id = item.id
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0

        this.tablePrices.items = item.prices
        this.itemSelected = item

        this.modal.formPrice.active = true
        this.modal.formPrice.title = "Mantenimiento de Precios"

        this.loadStyleConfig()
      },
      editPricesProducts(item) {
        this.crud.formPrice.id = item.id
        this.crud.formPrice.products_id = item.products_id
        this.crud.formPrice.price_list_select = { code: item.list.id, label: item.list.name}
        this.crud.formPrice.price_list_id = item.list.id
        this.crud.formPrice.amount = item.amount_initial      
        this.crud.formPrice.amount_old = item.amount_initial
      },
      cancelSaveFormPrice(){
        this.crud.formPrice.id = 0
        this.crud.formPrice.price_list_select = null
        this.crud.formPrice.price_list_id = null
        this.crud.formPrice.amount = 0
        this.crud.formPrice.amount_old = 0
      },
      removePricesProducts(item) {
        var id = item.id
        var listName = 'LISTA: ' + item.list.name        
        var listAmount = 'PRECIO: ' + Intl.NumberFormat('es-AR',{style:'currency',currency:item.list.currency.code}).format(item.amount_total)

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item de la ' + listName + ' con ' + listAmount + '?', {
          title: 'Borrar Precio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.eliminarPrecioVariedad(id);

            result.then((response) => {      
              loader.hide()
              this.filterProducts(false, true)
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          } 
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })
      },
      saveFormPrice(){
        let loader = this.$loading.show();

        if(this.crud.formPrice.price_list_select) {
          this.crud.formPrice.price_list_id = this.crud.formPrice.price_list_select.code
        }        

        if (this.crud.formPrice.id) {
          var result = serviceAPI.editarPrecioVariedad(this.crud.formPrice)
        } else {
          var result = serviceAPI.agregarPrecioVariedad(this.crud.formPrice)
        }

        result.then((response) => {                         
          loader.hide()
          this.filterProducts(true, true)          
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },

      openInterested(item){
        this.saveFilterStorage()
        this.$router.push({ name: 'ProductsStaffCrudContactInterested', params: {productID: item.id} })
      },     
      productRefused(item) {
        var code = item.code
        var name = item.name        

        this.$bvModal.msgBoxConfirm('¿Rechazar el producto (' + code + ') - '+ name + '?', {
          title: 'Rechazar Producto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.refusedProductMarketplace({
              id: item.id
            });

            result.then((response) => {      
              loader.hide()
              this.filterProducts()
              this.$awn.success("Producto rechazado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },
      getInerestedActive(arr) {
        var total = 0
        if(arr) {
          arr.forEach(element => {
            if(element.active) {
              total = total + 1
            }
          });
        }
        return total
      },

      // MARCO PRODUCTO COMO PUBLICADO EN INSTAGRAM
      checkPublishedInstagram(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.checkPublishedInstagramProductMarketplace({
          id: item.id          
        });
        
        result.then((response) => {          
          loader.hide()
          this.filterProducts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
      },
      // MARCO PRODUCTO COMO NO PUBLICADO EN INSTAGRAM
      uncheckPublishedInstagram(item) {
        let loader = this.$loading.show();

        var result = serviceAPI.uncheckPublishedInstagramProductMarketplace({
          id: item.id
        });
        
        result.then((response) => {          
          loader.hide()
          this.filterProducts()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })  
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_crud_product_marketplace', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_crud_product_marketplace')) {
          this.filters = JSON.parse(Storage.getValue('filter_crud_product_marketplace'))
        }         
      },      
    }    
  }
</script>
<style>
  .crud-products-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-products-code {
    color: gray;
  }
  .crud-product-image {
    height: 40px;
  }

  .products-tablesub-observations {
    color: darkred;
  }
  .crud-product-table-show {
    font-size: 15px;
  }  
  .crud-product-add-variant {
    margin-top: 5px;
  }
  .crud-product-close-variant  {
    margin-top: -15px;
  }
  .crud-product-action-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-product-action-icon-stock {
    color: #000 !important;
    margin-right: 5px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-products-button-save-price {
    margin-top: 30px;
  }  
  .crud-product-list-prices-public {
    font-size: 10px;
    font-weight: 600;
    color: gray;
  }
  .crud-products-variant-positions {
    position: fixed;
  }
  #variant_items {
    position: fixed;
    right: 15px;    
  }     
  .crud-products-table-prices {
    min-height: 100px;
  }
  .crud-product-interested {
    font-size: 13px;
  }
  .crud-product-featured-custom {
    color: red; 
    font-size: 15px;
    float: right;     
  }  
</style>